<template>
	<div class="app-container" style="background-color: #fff;">
		<div
			style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;"
		>
			<div>
				<buttonPermissions :datas="'supplierDelAll'">
					<el-button type="warning" style="width:100px;" @click="deleteChose">批量删除</el-button>
				</buttonPermissions>
				<buttonPermissions :datas="'supplierAdd'">
					<el-button type="primary" style="width:100px;margin-left:10px;" @click="addSupply">添加供货商</el-button>
				</buttonPermissions>
			</div>
			<div>
				<el-input
					v-model="keyWord"
					placeholder="供应商名称"
					style="width:250px;margin-right: 10px;"
					clearable
					@keyup.enter.native="keySearch"
				></el-input>
				<el-button type="primary" style="width:70px;" @click="keySearch">搜索</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table
				:data="supplierList"
				v-loading="dataloading"
				style="width: 100%;margin-bottom: 20px;"
				row-key="id"
				border
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="supplierName" label="供货商名称"></el-table-column>
				<el-table-column prop="contactName" label="联系人"></el-table-column>
				<el-table-column prop="contactPhone" label="联系电话"></el-table-column>
				<el-table-column prop="contactAddress" label="联系地址"></el-table-column>
				<el-table-column prop="supplierDesc" label="供货商描述"></el-table-column>
				<el-table-column prop="extendId" label="仓库ID"></el-table-column>
				<el-table-column prop="dockPeople" label="对接人"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scop">
						<div style="display:flex;">
							<buttonPermissions :datas="'supplierEdit'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="editSupply(scop.row)"
								>编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'supplierDel'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="deleteCate(scop.row)"
								>删除</el-button>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-pagination
			v-if="Total"
			style="margin:20px 0;float:right;"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="currentPage"
			:page-sizes="[10, 20, 30, 40, 50]"
			:page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="Total"
		></el-pagination>

		<!-- 供货商 弹框 -->
		<el-dialog :visible.sync="supplyVisable" width="800px" :title="popTitle">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="供货商名称:"
						prop="supplierName"
						:rules="[{required:true,message:'供货商名称不能为空'}]"
					>
						<el-input
							v-model="ruleForm.supplierName"
							placeholder="请输入供货商名称"
							maxlength="20"
							clearable
							style="width:350px"
						></el-input>
					</el-form-item>
					<el-form-item label="联系人名称:" prop="contactName" :rules="[{required:true,message:'联系人名称不能为空'}]">
						<el-input
							v-model="ruleForm.contactName"
							placeholder="请输入联系人名称"
							maxlength="20"
							clearable
							style="width:350px"
						></el-input>
					</el-form-item>
					<el-form-item
						label="联系人电话:"
						prop="contactPhone"
						:rules="[{required:true,message:'联系人电话不能为空'},{ min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }]"
					>
						<el-input
							v-model="ruleForm.contactPhone"
							placeholder="请输入11位手机号"
							maxlength="11"
							clearable
							style="width:350px"
						></el-input>
					</el-form-item>
					<el-form-item label="预留字段:" prop="extendId">
						<el-input
							v-model="ruleForm.extendId"
							placeholder="请输入预留字段"
							maxlength="30"
							clearable
							style="width:350px"
						></el-input>
					</el-form-item>
					<el-form-item
						label="联系人地址:"
						prop="contactAddress"
						:rules="[{required:true,message:'联系人地址不能为空'}]"
					>
						<el-input
							v-model="ruleForm.contactAddress"
							placeholder="请输入联系人地址"
							maxlength="50"
							clearable
							style="width:350px"
							type="textarea"
						></el-input>
					</el-form-item>
					<el-form-item label="供货商描述:" prop="supplierDesc">
						<el-input
							v-model="ruleForm.supplierDesc"
							placeholder="请输入供货商描述"
							maxlength="100"
							clearable
							style="width:350px"
							type="textarea"
						></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="supplyVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange()">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	supplierGetList,
	supplierDel,
	supplierSave
} from "../../api/goods.js"
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			supplyVisable: false,
			ruleForm: {
				supplierName: '',
				contactName: '',
				contactPhone: '',
				extendId: '',
				contactAddress: '',
				supplierDesc: '',
				id: 0
			},
			dataloading: false,
			supplierList: [],
			keyWord: '',
			currentPage: 1,
			pageSize: 20,
			Total: 0,
			selectList: [],
			popTitle: '新增供货商'
		}
	},
	created () {
		this.dataloading = true;
		this.initData();
	},
	methods: {
		keySearch () {
			// if(!this.keyWord){
			// 	this.$message({
			// 		showClose: true,
			// 		type: 'error',
			// 		message: "请输入供应商名称"
			// 	});
			// }else{
			// 	this.initData();
			// }
			this.initData();
		},
		async initData () {
			try {
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					supplierName: this.keyWord
				};
				let res = await supplierGetList(data);
				if (res.success) {
					this.supplierList = res.data.records;
					this.Total = res.data.total;
					this.dataloading = false;
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
		},
		async saveData () {
			try {
				let data = {
					contactAddress: this.ruleForm.contactAddress,
					contactName: this.ruleForm.contactName,
					contactPhone: this.ruleForm.contactPhone,
					extendId: this.ruleForm.extendId,
					supplierDesc: this.ruleForm.supplierDesc,
					supplierName: this.ruleForm.supplierName,
					id: this.ruleForm.id ? this.ruleForm.id : '',
				};
				let res = await supplierSave(data);
				if (res.success) {
					this.supplyVisable = false;
					var str = this.ruleForm.id ? "供货商编辑成功" : '供货商添加成功';
					this.$message({
						showClose: true,
						type: 'success',
						message: str
					});
					this.initData();
				} else {
					this.$message({
						showClose: true,
						type: 'warning',
						message: res.alertMsg
					});
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
		},
		async deleteData (list) {
			try {
				this.$confirm('是否确认删除这个供应商？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let res = await supplierDel(list);
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功'
						});
						this.initData();
					}
				})

			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
		},
		saveChange () {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try {
						this.saveData();
					} catch (e) {
						//TODO handle the exception
					}
				}
			});
		},
		// 切换显示条数
		handleSizeChange (val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.initData();
		},

		// 翻页
		handleCurrentChange (val) {
			this.currentPage = val;
			this.initData();
		},
		deleteChose () {
			if (!this.selectList.length) {
				this.$message({
					showClose: true,
					type: 'error',
					message: '请选择操作的供应商'
				});
				return;
			}
			var list = [];
			this.selectList.map(item => {
				list.push(item.id);
				return item;
			})
			this.deleteData(list);
		},
		deleteCate (row) {
			var list = [];
			list.push(row.id);
			this.deleteData(list);
		},
		addSupply () {
			this.$router.push({ path: '/goods/addSupplier', query: {supplierInfo:null} })
			// this.popTitle = "新增供货商";
			// this.ruleForm.supplierName = '';
			// this.ruleForm.contactName = '';
			// this.ruleForm.contactPhone = '';
			// this.ruleForm.extendId = '';
			// this.ruleForm.contactAddress = '';
			// this.ruleForm.supplierDesc = '';
			// this.ruleForm.id = 0;
			// this.supplyVisable = true;
		},
		editSupply (row) {
			var str = JSON.stringify(row);
			this.$router.push({ path: '/goods/addSupplier', query: { supplierInfo: str} })
			// this.popTitle = "供货商管理";
			// this.ruleForm.supplierName = row.supplierName;
			// this.ruleForm.contactName = row.contactName;
			// this.ruleForm.contactPhone = row.contactPhone;
			// this.ruleForm.extendId = row.extendId;
			// this.ruleForm.contactAddress = row.contactAddress;
			// this.ruleForm.supplierDesc = row.supplierDesc;
			// this.ruleForm.id = row.id;
			// this.supplyVisable = true;
		},
		handleSelectionChange (val) {
			this.selectList = val;
		},
	}
}
</script>

<style lang="less">
</style>
